import Vue from 'vue'
import VueRouter from 'vue-router'
import { Toast } from 'vant'
import { SESSION_NAME } from '../utils/login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/page/home.vue')
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import(/* webpackChunkName: "mine" */ '@/page/mine.vue')
  },
  {
    path: '/orderlist/:type',
    name: 'orderlist',
    component: () => import(/* webpackChunkName: "orderlist" */ '@/page/orderlist.vue')
  },
  {
    path: '/orderdetail',
    name: 'orderdetail',
    component: () => import(/* webpackChunkName: "orderdetail" */ '@/page/orderdetail.vue')
  },
  {
    path: '/bookconfirm',
    name: 'bookconfirm',
    component: () => import(/* webpackChunkName: "bookconfirm" */ '@/page/bookconfirm.vue')
  },
  {
    path: '/bookdone',
    name: 'bookdone',
    component: () => import(/* webpackChunkName: "bookdone" */ '@/page/bookdone.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '@/page/detail.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/page/login.vue')
  },
  {
    path: '/vipguide',
    name: 'vipguide',
    meta: {
      title: '百动柏纳仕会员'
    },
    component: () => import(/* webpackChunkName: "login" */ '@/page/vipguide.vue')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const needLoginRoutes = ['mine', 'bookconfirm']
  // 修改标题
  document.title = (to.meta && to.meta.title) ? to.meta.title : '精品酒店'
  // 需要登录才可以跳转
  if (needLoginRoutes.includes(to.name) && !localStorage.getItem(SESSION_NAME)) {
    const query = {
      from: to.name,
      hcatid: to.query.hcatid,
      hid: to.query.hid,
      pcatid: to.query.pcatid,
      pid: to.query.pid
    }
    Toast('请先登录')
    next({ name: 'login', query })
  } else {
    next()
  }
})

export default router