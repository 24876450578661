<template>
  <div id="app" class="app">
    <van-nav-bar
      v-if="hasTitlebarRoutes.includes($route.name)"
      :title="navbarTitle"
      left-text="返回"
      left-arrow
      fixed
      @click-left="handleClickTitleLeft"
    />
    <router-view />
    <van-tabbar v-model="active" active-color="#ee0a24" v-if="hasTabbarRoutes.includes($route.name)">
      <van-tabbar-item
        v-for="item of tabbarData"
        :key="item.key"
        :icon="item.icon"
        :name="item.key"
        @click="handleToPage(item.pathname)"
      >
        {{ item.title }}
      </van-tabbar-item>
    </van-tabbar>
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      hasTabbarRoutes: ['home', 'mine'],
      hasTitlebarRoutes: ['orderlist', 'orderdetail', 'bookconfirm', 'detail'],
      active: 'home',
      tabbarData: [
        {
          key: 'home',
          title: '首页',
          icon: 'home-o',
          pathname: 'home'
        },
        {
          key: 'mine',
          title: '我的',
          icon: 'user-circle-o',
          pathname: 'mine'
        }
      ]
    }
  },
  provide: {
    app: this
  },
  methods: {
    handleToPage(pathname) {
      if (pathname === this.$route.name) return
      this.$router.push({ name: pathname })
    },
    handleClickTitleLeft() {
      history.go(-1)
    },
  },
  computed: {
    navbarTitle() {
      switch(this.$route.name) {
        case "orderlist":
          return '订单列表'
        case 'orderdetail':
          return '订单详情'
        case 'bookconfirm':
          return '购买确认'
        case 'detail':
          return '酒店详情'
        default:
          return ''
      }
    }
  },
  watch: {
    '$route'(v) {
      this.active = v.name || 'home'
    }
  },
}
</script>

<style lang="scss">
html {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue',
  Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', 'miui', 'Hiragino Sans GB',
  'Microsoft Yahei', sans-serif;
  min-height: 100%;
  background: #fafafa;
}

.app {
  padding-bottom: 50px;
}

.tabs {
  position: fixed !important;
  left: 0;
  top: 0;
  z-index: 10;
}
</style>
