export const SESSION_NAME = 'tr_token'
const PHONE_SESSION_NAME = 'tr_phone'

export const isLogin = () => {
  return !!localStorage.getItem(SESSION_NAME)
}

const clearPhone = () => {
  localStorage.removeItem(PHONE_SESSION_NAME)
}

export const loginSession = (session, phone) => {
  localStorage.setItem(SESSION_NAME, session)
  localStorage.setItem(PHONE_SESSION_NAME, phone)
}

// const setPhone = (phone) => {
//   localStorage.setItem(PHONE_SESSION_NAME, phone)
// }

export const getPhone = () => {
  return localStorage.getItem(PHONE_SESSION_NAME)
}

export const logout = () => {
  localStorage.removeItem(SESSION_NAME)
  clearPhone()

  // logoutApi({ token }).then(res => {
  //   console.log('res: ', res)
  // })
}