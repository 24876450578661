import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible'
import {
  Button as VanButton,
  Tab,
  Tabs,
  Tabbar,
  TabbarItem,
  Icon,
  Card,
  Divider,
  Lazyload,
  Swipe,
  SwipeItem,
  Image as VanImage,
  NavBar,
  NumberKeyboard,
  Cell,
  CellGroup,
  Collapse,
  CollapseItem,
  Field,
  Empty,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Calendar,
  SubmitBar,
  Sku,
  Stepper,
  RadioGroup,
  Radio,
  Form,
  Toast
} from 'vant'
import 'vant/lib/index.css'

Vue.config.productionTip = false

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Card)
Vue.use(Icon)
Vue.use(VanImage)
Vue.use(NavBar)
Vue.use(VanButton)
Vue.use(Divider)
Vue.use(NumberKeyboard)
Vue.use(Lazyload)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Field)
Vue.use(Card)
Vue.use(Empty)
Vue.use(GoodsAction)
Vue.use(GoodsActionButton)
Vue.use(GoodsActionIcon)
Vue.use(Calendar)
Vue.use(SubmitBar)
Vue.use(Sku)
Vue.use(Stepper)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Form)
Vue.use(Toast)
Vue.use(Collapse)
Vue.use(CollapseItem)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
